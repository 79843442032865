/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { jsx } from 'theme-ui';
import { Box, Card, Flex, AspectImage, Text, Image } from 'theme-ui';
import slugify from 'slugify';

import Phone from '../components/icons/phone';

const BusinessCard = ({ data, isSearching }) => {
  const style = {
    textTransform: 'uppercase',
    borderRadius: '10px',
    boxSizing: 'border-box',
    boxShadow: 'none!important',
    cursor: 'pointer',
  };

  const renderCategories = color =>
    data.categories.map(category => (
      <Text
        sx={{
          mr: [1, 2, 3],
          mb: 3,
          bg: color ? color : 'primary',
          color: color ? '#111' : '#fff',
          paddingX: 3,
          paddingY: 1,
          fontWeight: 600,
          borderRadius: '13px',
          fontSize: [0, 1, 2],
          fontFamily: 'lato',
        }}>
        {category}
      </Text>
    ));

  if (isSearching) {
    return (
      <Link
        to={`/places/${slugify(data.name, { lower: true })}`}
        sx={{ textDecoration: 'none', height: '75%' }}>
        <Card
          sx={{
            cursor: 'pointer',
            transition: 'all .5s',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid black',
            '&:hover': {
              boxShadow: '0 0 30px rgba(0,0,0,.6)',
              transition: 'all .5s',
            },
          }}>
          <Box
            sx={{
              height: '100%',
              overflow: 'hidden',
              '&:hover img': {
                transform: 'scale(1.25)',
              },
            }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform .75s ease',
              }}
              src={data.cover.replace('650', '1200')}
              alt='image'></Image>
          </Box>
          <Box sx={{ padding: 3, position: 'relative', zIndex: 999 }}>
            <Text
              variant='heading'
              sx={{ color: 'text', fontWeight: 'bold', fontSize: [3, 5, 6] }}
              as='p'>
              {data.name}
            </Text>

            {/* <Flex sx={{ flexWrap: 'wrap', mt: 3 }}>{renderCategories()}</Flex> */}
          </Box>
        </Card>
      </Link>
    );
  }

  return (
    <Card
      style={style}
      sx={{
        cursor: 'pointer!important',
        width: ['275px', '400px', '500px'],
        fontFamily: 'lato',
        overflowX: 'hidden',
      }}>
      <Box>
        <AspectImage
          sx={{
            borderTopRightRadius: '13px',
            borderTopLeftRadius: '13px',
            width: '100%',
          }}
          ratio={16 / 9}
          src={data.cover}
          alt='image'></AspectImage>
      </Box>
      <Box
        sx={{
          padding: 3,
          borderBottomRightRadius: '13px',
          borderBottomLeftRadius: '13px',
          borderTop: 'none',
          bg: 'text',
        }}>
        <Text sx={{ color: 'white', fontWeight: 'bold', mb: 3 }} as='p'>
          {data.name}
        </Text>
        <Flex sx={{ flexWrap: 'wrap' }}>{renderCategories('white')}</Flex>
      </Box>
    </Card>
  );
};

export default BusinessCard;
